import React, { useCallback, useMemo } from 'react';
import { useNavigate }  from "react-router-dom";
import MenuButton from '../../../shared/MenuButton';
import logoSrc from '../../../assets/pics/Logo.png';
import './contactPage.scss';

const WayOfContact = ({ setCursorText, setShouldMouseBeScaled, label, value, isLink, linkTo, shouldOpenNewTab, ...liProps }) => {

  const makeClickable = useCallback((text) => ({
    onMouseEnter: () => {
      setCursorText(text);
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  let linkProps = !!isLink ? {
    className: "contact__way-value",
    href: linkTo
  } : {};

  if (!!isLink && !!shouldOpenNewTab) {
    linkProps = {
      ...linkProps,
      target: "_blank"
    }
  }

  return (
    <li className="contact__way" {...liProps}>
      {
        !!isLink ? <a {...linkProps} {...makeClickable(label)}>{value}</a> : <p className="contact__way-value">{value}</p>
      }
    </li>
  );
};

const ContactPage = ({ setShouldMouseBeScaled, setCursorText }) => {

  const navigate = useNavigate();



  const waysOfContact = useMemo(() =>  ([
    {
      label: "Napisz",
      value: "marszalek_a@op.pl",
    },
    {
      isLink: true,
      linkTo: "tel:+48695756700",
      label: "ZADZWOŃ",
      value: "+48 695 756 700"
    },
    {
      isLink: true,
      linkTo: "tel:+48601931692",
      label: "ZADZWOŃ",
      value: "+48 601 931 692"
    },
    {
      isLink: true,
      shouldOpenNewTab: true,
      linkTo: "https://www.google.com/maps/place/Pod%C5%82%C4%99%C5%BCe+113,+32-003+Pod%C5%82%C4%99%C5%BCe/@50.0128267,20.1652104,17z/data=!3m1!4b1!4m5!3m4!1s0x4716473dcf06967d:0xe81ecb21d3c5c0e6!8m2!3d50.0128267!4d20.1673991?hl=pl",
      label: "MAPA",
      value: "Podłęże 113, 32-003"
    }
  ]), [navigate]);

  return (
    <main className="contact-page">
        <meta name="title" content="Kontakt" />
        <meta name="description" content="Zadzwoń lub napisz do nas!" />
      <MenuButton setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />
      <div className="container contact-page__container">
        <img src={logoSrc} className="contact-page__logo" alt="Logo" />
        <ul className="contact__ways-list">
          { waysOfContact.map(way => <WayOfContact {...way} setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />) }
        </ul>
      </div>
    </main>
  )
};

export default ContactPage;