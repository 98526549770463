import React, { forwardRef, useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './numbers.scss';

const NumbersSection = (props, ref) => {

  const paragraphRefs = [];
  const sectionContainerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(paragraphRefs, {
      scrollTrigger: {
        trigger: sectionContainerRef.current,
        start: "top 70%"
      },
      opacity: 1,
      translateY: 0,
      stagger: 0.1,
      duration: .7
    })

  }, []);

  return (
    <section className="numbers" ref={ref}>
      <div className="container numbers__container" ref={sectionContainerRef}>
        <div className="numbers__paragraph-container">
          <p className="numbers__paragraph" ref={ref => paragraphRefs[0] = ref}>ponad 5 lat <span>doświadczenia</span></p>
          <p className="numbers__paragraph" ref={ref => paragraphRefs[1] = ref}>6 zakończonych <span>inwestycji</span></p>
          <p className="numbers__paragraph" ref={ref => paragraphRefs[2] = ref}>50+ sprzedanych <span>mieszkań</span></p>
        </div>
      </div>
    </section>
  )
};

export default forwardRef(NumbersSection);
