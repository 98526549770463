import React, { forwardRef } from 'react';
import './contact.scss';
import LinkWithArrow from '../../../shared/LinkWithArrow';

const ConctactSection = (props, ref) => {

  return (
    <section className="contact-section" ref={ref}>
      <div className="container contact-section__container">
        <div className="contact-section__text-container">
          <p className="contact-section__paragraph">
            zainteresowała Cię nasza oferta?
          </p>
          <LinkWithArrow className="contact-section__button" textContent="skontaktuj się z nami" to="/kontakt" />
        </div>
      </div>
    </section>
  )
};

export default forwardRef(ConctactSection);