import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { useMouse } from '../shared/useMouse';

const isEmpty = string => string?.length === 0;

const Cursor = ({ cursorText, shouldMouseBeScaled }) => {

  const { posX: mouseX, posY: mouseY } = useMouse();

  const bigBall = useRef(null);
  const cursorTextRef = useRef(null);

  useEffect(() => {
    gsap.to(bigBall.current, {
      x: mouseX - 20,
      y: mouseY - 20,
      duration: 0
    });
  }, [mouseX, mouseY]);

  useEffect(() => {
    if (!isEmpty(cursorText)) {
      gsap.to(cursorTextRef.current, {
        x: mouseX - 30,
        y: mouseY + 10,
        opacity: 1,
        duration: 0
      })
    }
  }, [mouseX, mouseY, cursorTextRef, cursorText]);

  useEffect(() => {
    gsap.to(bigBall.current, {
      scale: shouldMouseBeScaled ? 3 : 1,
      duration: .3
    });
  }, [shouldMouseBeScaled]);

  return (
    <div className="cursor">
      <div className={`cursor__ball cursor__ball--big`} ref={bigBall}>
        <svg height="40" width="40">
          <circle cx="20" cy="20" r="19" strokeWidth="0" />
        </svg>
      </div>
      {cursorText?.length >= 1 &&
      <div className="cursor__text-wrapper" ref={cursorTextRef}>
        <span className="cursor__text" >{cursorText}</span>
      </div> }
    </div>
  )
};

export default Cursor;