import React from 'react';
import MenuButton from '../../../shared/MenuButton';
import InvestmentsCardSlider from '../../../shared/InvestmentsCardSlider';
import LogoSrc from '../../../assets/pics/logo-blue.png';
import './investments.scss';

const AllInvestments = ({ setShouldMouseBeScaled, setCursorText }) => {

  return (
    <main>
        <meta name="title" content="Obecne inwestycje" />
        <meta name="description" content="Sprawdź domy i mieszkania w Niepołomicach w naszej ofercie nieruchomości." />
      <MenuButton className="menu__button--black" setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />
      <section className="investments-page">
        <div className="container investments-page__container">
          <h1 className="investments-page__heading">obecne inwestycje</h1>
          <InvestmentsCardSlider setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />
          <img src={LogoSrc} alt="Twój Dom Niepołomice" className="investments-page__logo" />
        </div>
      </section>
    </main>
  )
};

export default AllInvestments;