import React, { forwardRef, useEffect, useMemo } from "react";
import gsap from 'gsap';
import './distance.scss';
import trasySrc from '../../../assets/pics/trasy.png';

const DistanceSection = (props, ref) => {

  const paragraphRefs = useMemo(() => [], []);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

    gsap.to(gsap.utils.toArray(paragraphRefs),  {
      scrollTrigger: {
        trigger: paragraphRefs[0],
        start: "top 70%"
      },
      y: 0,
      duration: .5,
      stagger: 0.15
    });

    return () => {
      tl.kill();
    }
  }, [paragraphRefs]);

  return (
    <section className="distance" ref={ref}>
      <div className="container distance__container">
        <p className="distance__paragraph">
          <span className="distance__paragraph-line" ref={ref => paragraphRefs[0] = ref}>daleko jedynie </span>
        </p>
        <p className="distance__paragraph">
          <span className="distance__paragraph-line" ref={ref => paragraphRefs[1] = ref}>do zgiełku </span>
        </p>
        <p className="distance__paragraph">
          <span className="distance__paragraph-line" ref={ref => paragraphRefs[2] = ref}>wielkiego miasta</span>
        </p>
        <div className="distance__image-wrapper">
          <img src={trasySrc} className="distance__image" alt="Trasy" />
        </div>
      </div>
    </section>
  );
};

export default forwardRef(DistanceSection);