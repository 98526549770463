import React, { useEffect, useMemo, useRef } from 'react';
import { gsap } from "gsap";
import Header from '../sections/header/Header';
import VideoSection from '../sections/video/VideoSection';
import DistanceSection from '../sections/distance/DistanceSection';
import InvestmentsSection from '../sections/investments/InvestmentsSection';
import NumbersSection from '../sections/numbers/NumbersSection';
import ConctactSection from '../sections/contact/ContactSection';
import Footer from '../sections/footer/Footer';
import MenuButton from '../../shared/MenuButton';

const BLUE = "#14213D";
const BLACK = "#000000";

const Homepage = ({ setCursorText, setShouldMouseBeScaled }) => {

  const investmentsSectionRef = useRef(null);
  const distanceSectionRef = useRef(null);
  const videoSectionRef = useRef(null);
  const numbersSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const footerRef = useRef(null);
  const mainRef = useRef(null);

  const animationStart = useMemo(() => "top 15%", []);
  const animationEnd = useMemo(() => "top 85%", []);

  useEffect(() => {
    gsap.set(mainRef.current, { backgroundColor: BLUE });

    gsap.to(mainRef.current, {
      scrollTrigger: {
        trigger: distanceSectionRef.current,
        start: animationStart,
        end: animationEnd,
        scrub: 1,
      },
      backgroundColor: BLACK
    });
  });

  return (
    <main ref={mainRef}>
        <meta name="title" content="Twój Dom Niepołomice" />
        <meta name="description" content="Mieszkania i domy na sprzedaż w Niepołomicach. Zamieszkaj z dala od miejskiego zgiełku." />

      <MenuButton setCursorText={setCursorText} setShouldMouseBeScaled={setShouldMouseBeScaled} />
      <Header setCursorText={setCursorText} setShouldMouseBeScaled={setShouldMouseBeScaled} />
      <VideoSection ref={videoSectionRef} />
      <DistanceSection ref={distanceSectionRef} />
      <InvestmentsSection ref={investmentsSectionRef} setCursorText={setCursorText} setShouldMouseBeScaled={setShouldMouseBeScaled} />
      <NumbersSection ref={numbersSectionRef} />
      <ConctactSection ref={contactSectionRef} />
      <Footer setCursorText={setCursorText} setShouldMouseBeScaled={setShouldMouseBeScaled} ref={footerRef} />
    </main>
  );
};

export default Homepage;