import React, { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Marquee from 'react-fast-marquee';
import { useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { client, GET_INVESTMENTS } from '../../../shared/getInvestments';
import MenuButton from '../../../shared/MenuButton';
import FlatsSection from './sections/flats/FlatsSection';
import icon3DSrc from '../../../assets/icons/3d.png';
import iconGalleryView from '../../../assets/icons/gallery-view.png';
import playIconSrc from '../../../assets/icons/play.png';
import pauseIconSrc from '../../../assets/icons/pause.png';
import './investment.scss';
import { useWindowDimensions } from '../../../shared/useWindowsDimensions';

const getContainerWidthForWindowWidth = (windowWidth = 1920) => {
  if (windowWidth <= 760) {
    return 320;
  } else if (windowWidth <= 960) {
    return 720;
  } else if (windowWidth <= 1200) {
    return 900;
  } else if (windowWidth <= 1540) {
    return 1200;
  }

  return 1440;
};

const getInvestmentData = (data = {}, id) => ((data.allInwestycjes || []).find(i => i.data.id === id) || {}).data || null;

const Investment = ({ investmentDataPassed = null, setShouldMouseBeScaled, setCursorText }) => {

  const { id } = useParams();
  const [isSliderPlaying, setIsSliderPlaying] = useState(true);
  const [investmentData, setInvestmentData] = useState(null);
  const [isGalleryViewEnabled, setIsGalleryViewEnabled] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const titleRef = useRef(null);
  const menuButtonRef = useRef(null);
  const flatsSectionRef = useRef(null);
  const { width: windowWidth } = useWindowDimensions();

  const { loading: isLoading, error, data } = useQuery(GET_INVESTMENTS, { client, enabled: investmentDataPassed === null });


  const clickable = useMemo(() => ({
    onMouseEnter: () => {
      setCursorText('ODKRYJ');
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  useEffect(() => {
    if (!isLoading && !error && !!data) {
      setInvestmentData(getInvestmentData(data, id));
    }
  }, [id, isLoading, error, data]);

  useEffect(() => {
    if ((getInvestmentData(data, id) === null && !isLoading) || error) {
      setShouldRedirect(true);
    }
  }, [data, isLoading, error, id])

  useEffect(() => {
    gsap.fromTo(titleRef.current, {
      translateY: 70,
      opacity: 0,
      duration: 1,
      delay: .5,
      ease: "none"
    }, {
      translateY: 0,
      opacity: 1
    })
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(menuButtonRef.current, {
      scrollTrigger: {
        trigger: flatsSectionRef.current,
        start: "center 0",
        end: "top 0",
        scrub: true
      },
      duration: .7,
      color: "white",
      ease: "none"
    })
  }, []);

  return (
    <>
        <meta name="robots" content="noindex" />
      {shouldRedirect && <Navigate replace to={"/"} />}
      {isLoading && <div>LOADING</div>}
      {
        !isLoading && investmentData !== null && !error &&
        <main>
          <MenuButton setCursorText={setCursorText} setShouldMouseBeScaled={setShouldMouseBeScaled} className="menu__button--black" ref={menuButtonRef} />
          <section className="investment__title-section">
            <div className="container investment__title-section-container">
              <div className="investment__title-container">
                <h1 className="investment__title" ref={titleRef}>{String(investmentData.displayName).toLowerCase()}</h1>
              </div>
              <div className="investment__title-section-pics-container">
                {
                  investmentData.photos.slice(0, 3).map((photoSrc, index) => <img src={photoSrc} alt="Visualisation" className={`investment__title-section-photo investment__title-section-photo--${index}`} />)
                }
              </div>
            </div>
          </section>
          <section className="investment__gallery-section">
            <div className="container investment__gallery-section-container">
              <h4 className="investment__gallery-section-title">
                <span>
                  zobacz tę inwestycję
                </span>
              </h4>
              <h4 className="investment__gallery-section-title">
                <span>
                  dokładnie.
                </span>
              </h4>
              <h4 className="investment__gallery-section-title">
                <span>
                  z każdej strony.
                </span>
              </h4>
              <div className="investment__gallery-section-controls-container">
                <div>
                  {
                    windowWidth > 960 && !!investmentData["3dModel"] &&
                    <button className={`investment__gallery-section-control investment__gallery-section-control--3d ${!isGalleryViewEnabled ? "investment__gallery-section-control--active" : ""}`} {...clickable} onClick={() => {
                      setIsGalleryViewEnabled(false)
                    }}>
                      <img src={icon3DSrc} alt="3d view" />
                    </button>
                  }
                  {
                    windowWidth <= 960 &&
                      <button className={`investment__gallery-section-control investment__gallery-section-control--play`} onClick={() => setIsSliderPlaying(prevState => !prevState)}>
                        <img src={isSliderPlaying ? pauseIconSrc : playIconSrc} alt="Play Pause" />
                      </button>
                  }
                  {
                    windowWidth > 960 &&
                    <button className={`investment__gallery-section-control investment__gallery-section-control--gallery ${isGalleryViewEnabled ? "investment__gallery-section-control--active" : ""}`} {...clickable} onClick={() => setIsGalleryViewEnabled(true)}>
                      <img src={iconGalleryView} alt="GalleryView view" />
                    </button>
                  }
                </div>
              </div>
              {
                isGalleryViewEnabled &&
                <div className="investment__gallery-container">
                  <Marquee play={isSliderPlaying} pauseOnHover={windowWidth > 960} gradient={false} speed={80}>
                    {
                      investmentData.photos
                        // .slice(0, Math.floor(investmentData.photos.length / 2))
                        .map(photoSrc =>
                          <img
                            src={photoSrc}
                            alt="Visualisation"
                            className={`investment__gallery-photo`}
                            key={`img-${photoSrc}`}
                          />)
                    }
                  </Marquee>
                  <Marquee play={isSliderPlaying} pauseOnHover={windowWidth > 960} gradient={false} speed={40} style={{ marginTop: 40 }} direction="right">
                    {
                      investmentData.photos
                        // .slice(Math.ceil(investmentData.photos.length / 2))
                        .map(photoSrc =>
                          <img
                            src={photoSrc}
                            alt="Visualisation"
                            className={`investment__gallery-photo`}
                            key={`img-${photoSrc}`}
                          />)
                    }
                  </Marquee>
                </div>
              }
              {
                !isGalleryViewEnabled && windowWidth > 960 &&
                <iframe title="3d Model" frameBorder={0} src="https://twojdomniepolomice.pl/wizualizacje/wrzosowa/rotate.html" height={getContainerWidthForWindowWidth(windowWidth) * 0.75} width={getContainerWidthForWindowWidth(windowWidth)} />
              }
            </div>
          </section>
          <section className="investment__map-section">
            <div className="container investment__map-section-container">
              <div className="investment__map-text-container">
                <p className="investment__address">
                  <span>{investmentData.address.street} {investmentData.address.buildingNumber}</span>
                  <span>{investmentData.address.postalCode} {investmentData.address.city}</span>
                </p>
              </div>
              <div className="investment__map-container">
                <iframe
                  className="investment-details__map"
                  loading="lazy"
                  style={{ border: 0, minWidth: "100%", minHeight: "100%" }}
                  allowFullScreen
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCHGJXKLPkL8GNbmtTPTd7E7cDF01mri-w&q=${investmentData.address.street}+${investmentData.address.buildingNumber},+${investmentData.address.postalCode}+${investmentData.address.city}`}
                />
              </div>
            </div>
          </section>
          <FlatsSection investment={investmentData} setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} ref={flatsSectionRef} />
        </main>
      }
    </>
  )
};

export default Investment;