import React, { useRef, useEffect, useState, useCallback } from 'react';
import './header.scss';
import LogoSrc from '../../../assets/pics/Logo.png';
import TwojDomSrc from '../../../assets/pics/twoj-dom.png';
import NiepolomiceSrc from '../../../assets/pics/niepolomice.png';
import Header1 from '../../../assets/pics/header-1.jpg';
import Header2 from '../../../assets/pics/header-2.jpg';
import Header3 from '../../../assets/pics/header-3.jpg';
import { useMouse } from '../../../shared/useMouse';
import { useWindowDimensions } from '../../../shared/useWindowsDimensions';

const getRotateDegrees = (currentMousePosition, windowDimension) =>
  ((windowDimension / 2 - currentMousePosition) / 200).toFixed(2);

const Header = ({ setCursorText, setShouldMouseBeScaled }) => {

  const container = useRef(null);
  const img1 = useRef(null);
  const img2 = useRef(null);
  const img3 = useRef(null);
  const sectionHeaderRef = useRef(null);
  const { posX: mouseX, posY: mouseY } = useMouse();

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [xAxis, setxAxis] = useState(0);
  const [yAxis, setyAxis] = useState(0);

  const getTransformStyle = useCallback(() => ({
      style: {
        transform: `rotateX(${yAxis}deg) rotateY(${-1 * xAxis}deg)`
      }
    }), [xAxis, yAxis]);

  const getTranslate3dStyle = useCallback(({ xMultiplier, yMultiplier }) => {
    if (windowWidth > 1200) {
      return {
        style: { transform: `translate3d(${((windowWidth / 2 - mouseX) / 80 * xMultiplier).toFixed(2)}px, ${((windowHeight / 2 - mouseY) / 80 * yMultiplier).toFixed(2)}px, 0)` }
      }
    }
    return {};
  }, [windowWidth, windowHeight, mouseX, mouseY]);

  useEffect(() => {
    if (windowWidth > 1200) {
      setxAxis(getRotateDegrees(mouseX, windowWidth));
    }
  }, [mouseX, windowWidth]);

  useEffect(() => {
    if (windowWidth > 1200) {
      setyAxis(getRotateDegrees(mouseY, windowHeight));
    }
  }, [windowWidth, mouseY, windowHeight]);

  return (
    <section className='header' ref={sectionHeaderRef}>
      <div className="container header__container" {...getTransformStyle()} ref={container}>
        <div className="header__column">
          {windowWidth >= 1200 && <img src={Header1} alt="Wizualizacja" className="header__pic header__pic--1" ref={img1} {...getTranslate3dStyle({ xMultiplier: -10, yMultiplier: -4 })} />}
          {windowWidth >= 1200 && <img src={TwojDomSrc} alt="Logo" className="header__logo header__logo--twoj" />}
          { windowWidth < 1200 && <img src={LogoSrc} alt="Logo" className="header__logo-mobile" /> }
        </div>
        <div className="header__column header__column--2">
          {windowWidth >= 1200 && <img src={NiepolomiceSrc} alt="Logo" className="header__logo header__logo--niepolomice" />}
          <img src={Header2} alt="Logo" className="header__pic header__pic--2" ref={img2} {...getTranslate3dStyle({ xMultiplier: 5, yMultiplier: 2 })} />
          <img src={Header3} alt="Logo" className="header__pic header__pic--3" ref={img3} {...getTranslate3dStyle({ xMultiplier: -3, yMultiplier: -4 })} />
        </div>
      </div>
    </section>
  )
};

export default Header;

