import React, { useEffect, forwardRef, useRef } from "react";
import { gsap } from "gsap";
import InvestmentsCardSlider from '../../../shared/InvestmentsCardSlider';
import './investments.scss';

const InvestmentsSection = ({ setCursorText, setShouldMouseBeScaled }, ref) => {

  const titleRef = useRef(null);

  useEffect(() => {
    gsap.to(titleRef.current, {
      scrollTrigger: {
        trigger: titleRef.current,
        scrub: true
      },
      translateY: 0
    })
  });

  return (
    <section className="investments-section" ref={ref}>
      <div className="container investments-section__container">
        <h1 className="investments-section__heading" ref={titleRef}>zobacz nasze inwestycje</h1>
        <InvestmentsCardSlider setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />
      </div>
    </section>
  )
};

export default forwardRef(InvestmentsSection);