import React, { useMemo, forwardRef } from 'react';
import puszczaSrc from '../../../assets/clips/puszcza.mp4';
import zamekSrc from '../../../assets/clips/zamek.mp4';
import './video.scss';
import { useWindowDimensions } from '../../../shared/useWindowsDimensions';


const VideoSection = (props, ref) => {

  const { width: windowWidth } = useWindowDimensions();

  const SLIDES = useMemo(() => ([
    {
      id: 0,
      videoSrc: puszczaSrc,
      heading: 'natury',
      description: 'Puszcza Niepołomicka to ponad 33 km szlaków turystycznych'
    },
    {
      id: 1,
      videoSrc: zamekSrc,
      heading: 'historii',
      description: 'Niepołomice to między innymi Zamek Królewski, nazywany również "Drugim Wawelem"'
    }
  ]), []);

  return (
    <>
      {
        SLIDES.map(({ heading, videoSrc, description }) => (
          <section className="video-section" ref={ref}>
            <div className="container video-section__container">
              <div className="video-section__column">
                <video src={videoSrc} className="video-section__video" muted loop autoPlay={windowWidth > 960} />
              </div>
              <div className="video-section__text-content">
                <h1 className="video-section__heading">zamieszkaj blisko <span>{heading}</span></h1>
                <p className="video-section__description">{description}</p>
              </div>
            </div>
          </section>
        ))
      }
    </>
  )
};

export default forwardRef(VideoSection);