import React, { useMemo, useState, forwardRef } from 'react';
import Menu from './Menu';

const MenuButton = ({ setCursorText, setShouldMouseBeScaled, className }, ref) => {

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const clickable = useMemo(() => ({
    onMouseEnter: () => {
      setCursorText('ROZWIŃ');
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  return (
    <>
      {
        !isMenuVisible && <button className={`menu__button ${className}`}{...clickable} onClick={() => {
          setCursorText('');
          setShouldMouseBeScaled(false);
          setIsMenuVisible(true)
        }} ref={ref}>menu</button>
      }
      {
        isMenuVisible && <Menu setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} setIsMenuVisible={setIsMenuVisible} />
      }
    </>
  )
};

export default forwardRef(MenuButton);