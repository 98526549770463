import React, { useCallback, forwardRef, useEffect, useRef } from 'react'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import logoSrc from '../../../assets/pics/Logo.png';
import './footer.scss';
import { useWindowDimensions } from '../../../shared/useWindowsDimensions';
import facebookIcon from "../../../assets/icons/facebook.png";


const Footer = ({ setCursorText, setShouldMouseBeScaled, shouldRenderHomepageLink = false, activeLink = null }, ref) => {

  const sectionContainerRef = useRef(null);

  const { width: windowWidth } = useWindowDimensions();

  const isLinkActive = useCallback((link) => link === activeLink, [activeLink]);
  const getClassNameOfActiveLink = useCallback((link) => isLinkActive(link) ? 'footer__menu-item--active' : '', [isLinkActive]);


  const makeClickable = useCallback((text, link) => (
    isLinkActive(link) ? {} : {
      onMouseEnter: () => {
        setCursorText(text);
        setShouldMouseBeScaled(true);
      },
      onMouseLeave: () => {
        setCursorText('');
        setShouldMouseBeScaled(false);
      }
    }), [setCursorText, setShouldMouseBeScaled]);

  useEffect(() => {
    if (!shouldRenderHomepageLink && windowWidth > 1200) {
      gsap.registerPlugin(ScrollTrigger);

      gsap.fromTo(sectionContainerRef.current, {
        display: "none",
        opacity: 0,
        translateY: 50
      }, {
        scrollTrigger: {
          trigger: sectionContainerRef.current,
          start: "top 70%",
          end: "center 95%"
        },
        display: "block",
        opacity: 1,
        translateY: 0,
        duration: 1.5
      })
    }
  }, [shouldRenderHomepageLink]);

  return (
    <footer className="footer" ref={ref}>
      <div className="container footer__container" ref={sectionContainerRef}>
        <img src={logoSrc} alt="Logo" className="footer__logo" />
        <ul className="footer__menu">
          {
            shouldRenderHomepageLink &&
            <li>
              <Link to="/" className={`footer__menu-item ${getClassNameOfActiveLink("/")}`} {...makeClickable("", "/")}>strona główna</Link>
            </li>
          }
          <li>
            <Link to="/inwestycje" className={`footer__menu-item ${getClassNameOfActiveLink("/inwestycje")}`} {...makeClickable("", "/inwestycje")}>inwestycje</Link>
          </li>
          <li>
            <Link to="/kontakt" className={`footer__menu-item ${getClassNameOfActiveLink("/kontakt")}`} {...makeClickable("", "/kontakt")}>kontakt</Link>
          </li>
        </ul>
        <p className="space-above">
          <a href="tel:+48695756700" className="footer__phone-number" {...makeClickable("ZADZWOŃ")}>+48 695 756 700</a>
        </p>
        <p>
          <a href="tel:+48601931692" className="footer__phone-number" {...makeClickable("ZADZWOŃ")}>+48 601 931 692</a>
        </p>
        <p className="space-above">
          <a href="mailto:marszalek_a@op.pl" className="footer__email" {...makeClickable("NAPISZ")}>marszalek_a@op.pl</a>
        </p>
        <p className="space-above">
          <a target="_blank" href="https://www.facebook.com/profile.php?id=100064171328340" className="footer__facebook-icon" {...makeClickable("FACEBOOK")}>
            <img src={facebookIcon} alt="facebook" />
          </a>
        </p>
        <p className="footer__copyrights">© 2021, Twój Dom Niepołomice</p>
      </div>
    </footer>
  )
};

export default forwardRef(Footer);