import React, { useMemo, useEffect, useState } from 'react';
import gsap from 'gsap';
import './flat.scss';

const Flat = ({ flat = {}, setSelectedFlat, setShouldMouseBeScaled, setCursorText, setShouldAppear }) => {

  const [shouldHide, setShouldHide] = useState(false);
  const refsArray = [];

  const clickable = useMemo(() => ({
    onMouseEnter: () => {
      setCursorText('POWRÓT');
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  useEffect(() => {
    gsap.fromTo(refsArray, {
      translateY: 50,
      opacity: 0
    }, {
      translateY: 0,
      opacity: 1,
      duration: .6,
      stagger: 0.1
    });

  }, []);

  useEffect(() => {
    if (shouldHide) {
      gsap.to(refsArray, {
        translateY: -50,
        opacity: 0,
        onComplete: () => {
          setSelectedFlat(null);
          setShouldAppear(true);
        }
      })
    }
  }, [setSelectedFlat, shouldHide]);

  return (
    <main className="flat-page">
        <meta name="robots" content="noindex" />
      <section className="flat">
        <div className="container flat__container">
          <button className="flat__back-button"
            {...clickable}
            onClick={() => {
              setCursorText('');
              setShouldMouseBeScaled(false);
              setShouldHide(true);
            }}
            ref={ref => refsArray[0] = ref}
          >
            powrót
          </button>
          <h1 className="flat__title" ref={ref => refsArray[1] = ref} >Mieszkanie {flat.flatNumber}</h1>
          <div className="flat__image-container" ref={ref => refsArray[2] = ref} >
            <img src={flat.flatImgUrl} alt="Rzut mieszkania" className="flat__image" />
          </div>
        </div>
      </section>
    </main>
  )
};

export default Flat;