import React from "react";
import { Link } from 'react-router-dom';

const LinkWithArrow = ({ to = "/", className = "", textContent = "" }) =>
  <Link to={to} className={`animated-arrow ${className}`}>
    <span className="the-arrow -left">
      <span className="shaft"/>
    </span>
    <span className="main">
      <span className="text">{textContent}</span>
      <span className="the-arrow -right">
        <span className="shaft"/>
      </span>
    </span>
  </Link>;

export default LinkWithArrow;