import { useState, useEffect } from "react";

export const useMouse = (relativeElement) => {
  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const getMousePos = e => {
      const posX = e.clientX;
      const posY = e.clientY;
      setMousePos({ posX, posY });
    };

    (relativeElement || document).addEventListener("mousemove", getMousePos);

    return function cleanup() {
      (relativeElement || document).removeEventListener("mousemove", getMousePos);
    };
  });

  return mousePos;
};

export const useScroll = () => {
  const [scrollTop, setScrollTop] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setIsScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return { scrollTop, isScrolling };
};