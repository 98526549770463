import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const TOKEN = "1657bfcb7cd83f936d39a2155f6dfa";
const httpLink = createHttpLink({
  uri: "https://graphql.datocms.com/"
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: Object.assign(
      headers || {},
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${TOKEN}`,
      }
    )
  }
});

export const GET_INVESTMENTS = gql`
  query {
      allInwestycjes {
        data
      }
  }
`;

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});