import React, { useState, useMemo, useEffect, useCallback, useRef, forwardRef } from 'react';
import gsap from 'gsap';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { getMinMaxProperty } from '../../../../../shared/InvestmentsCardSlider';
import areaIconSrc from '../../../../../assets/icons/area.png';
import stairsIconSrc from '../../../../../assets/icons/stairs.png';
import roomIconSrc from '../../../../../assets/icons/room.png';
import arrowIconSrc from '../../../../../assets/icons/arrow.png'
import './flatsSection.scss';
import Flat from '../../../flat/Flat';
import { useWindowDimensions } from '../../../../../shared/useWindowsDimensions';
import classNames from "classnames";
import flat from "../../../flat/Flat";

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const getSlideIndex = (current, increment = 0, max) => {
  if (current === 0 && increment === -1) {
    return current;
  }

  if (current === max && increment === 1) {
    return current;
  }

  return current + increment;
};

const RangeSlider = ({ min, max, width = 500, label, prop, rangeLabel = '', onChange = () => {} }) => {

  const [values, setValues] = useState([min, max]);
  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    debounce(() => onChange(values, prop), 500)();
  }, [values, prop]);

  return (
    <Box sx={{ width: windowWidth <= 760 ? 320 : width, marginBottom: windowWidth <= 760 ? 0 : windowWidth <= 1540 ? 1 : 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <label className="investment__flats-filter-label">{label}</label>
        </Grid>
        <Grid item xs>
          <Slider
            getAriaLabel={() => label}
            value={values}
            min={min}
            max={max}
            onChange={(event, newValues) => setValues(newValues)}
            color="secondary"
            valueLabelDisplay="on"
            valueLabelFormat={value => `${value} ${rangeLabel}`}
            sx={{
              color: "#FCA311",
              height: 2,
              '& .MuiSlider-thumb': {
                height: 10,
                width: 10,
                borderRadius: '1px',
              },
              '& .MuiSlider-valueLabel': {
                fontSize: 16,
                fontWeight: 'normal',
                top: 0,
                backgroundColor: 'unset',
                '&:before': {
                  display: 'none',
                },
                '& *': {
                  background: 'transparent',
                },
              },
            }}
            style={{
              cursor: "none"
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
};

const FlatsSection = ({ investment = {}, setShouldMouseBeScaled, setCursorText }, ref) => {

  const flatsAreaRange = useMemo(() => getMinMaxProperty(investment, 'area', false), [investment]);
  const floorsRange = useMemo(() => getMinMaxProperty(investment, 'floor'), [investment]);
  const roomsRange = useMemo(() => getMinMaxProperty(investment, 'rooms'), [investment]);

  const clickable = useCallback(({ isLeft = true, customText = '' }) => ({
    onMouseEnter: () => {
      setCursorText(customText.length > 0 ? customText : isLeft ? 'W LEWO' : 'W PRAWO');
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  const allFlats = useMemo(() =>
    [...investment.flats]
      .map(floor => floor.flats.map(flat => ({ ...flat, floor: floor.floor })))
      .flat()
    , [investment]);

  const [isTableViewEnabled, setIsTableViewEnabled] = useState(false);


  const INITIAL_FILTERS = useMemo(() => ({
    area: { ...flatsAreaRange },
    floor: { ...floorsRange },
    rooms: { ...roomsRange }
  }), [flatsAreaRange, floorsRange, roomsRange]);

  const [filteredFlats, setFilteredFlats] = useState([...investment.flats]);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedFlat, setSelectedFlat] = useState(null);
  const [shouldHide, setShouldHide] = useState({ value: false, flat: null });
  const [shouldAppear, setShouldAppear] = useState(false);

  const flatsSectionRef = useRef(null);

  const { width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    const filtered = allFlats.filter(flat =>
      Object
        .keys(filters)
        .map(filterKey => flat[filterKey] >= filters[filterKey].min && flat[filterKey] <= filters[filterKey].max)
        .every(value => value === true));

    setCurrentSlide(0);
    setFilteredFlats(filtered);
  }, [allFlats, filters]);

  useEffect(() => {
    if (shouldHide.value) {
      const tl = gsap.timeline();
      tl.to(flatsSectionRef.current.children[0], {
        translateY: -50,
        opacity: 0,
        display: "none",
        transformOrigin: "center",
        duration: .7
      });
      tl.to(flatsSectionRef.current, {
        backgroundColor: "white",
        duration: .5,
        onComplete: () => setSelectedFlat(shouldHide.flat)
      });
    }
  }, [shouldHide]);

  useEffect(() => {
    if (shouldAppear) {
      const tl = gsap.timeline();
      tl.to(flatsSectionRef.current, {
        backgroundColor: "#14213D",
        duration: .5,
      });
      tl.fromTo(flatsSectionRef.current.children[0], {
        transform: "scale(0.8)",
        opacity: 0,
        transformOrigin: "center",
        duration: .7
      }, {
        transform: "scale(1)",
        opacity: 1,
        onComplete: () => setShouldAppear(false)
      });
    }
  }, [shouldAppear]);

  return (
    <>
        <section className="investment__flats-section" ref={flatsSectionRef}>
          {
            selectedFlat === null &&
            <div className="container investment__flats-section-container" ref={ref}>
              <h1 className="investment__flats-section-title">{investment.type === "HOUSE" ? "domy" : "mieszkania"} w tej inwestycji:</h1>
              <div className="investment__flats-section-controls-container">
                {investment.type !== "HOUSE" &&
                  <div className="investment__flats-filters">
                    <RangeSlider
                      label="metraż"
                      rangeLabel="m2"
                      prop="area"
                      onChange={(value, prop) => {
                        setFilters(prevState => ({
                          ...prevState,
                          [prop]: { min: value[0], max: value[1] }
                        }))
                      }}
                      {...flatsAreaRange}
                    />
                    <RangeSlider
                      label="piętro"
                      prop="floor"
                      onChange={(value, prop) => {
                        setFilters(prevState => ({
                          ...prevState,
                          [prop]: { min: value[0], max: value[1] }
                        }))
                      }}
                      {...floorsRange}
                    />
                    <RangeSlider
                      label="ilość pokoi"
                      prop="rooms"
                      onChange={(value, prop) => {
                        setFilters(prevState => ({
                          ...prevState,
                          [prop]: { min: value[0], max: value[1] }
                        }))
                      }}
                      {...roomsRange}
                    />
                  </div>
                }
                <div className="investment__carousel-controls">
                  <button {...clickable({ customText: 'ZMIEŃ WIDOK' })} onClick={() => setIsTableViewEnabled(false)} className={classNames('investment__view-button', { 'active': !isTableViewEnabled })}>GALERIA</button>
                  <button {...clickable({ customText: 'ZMIEŃ WIDOK' })} onClick={() => setIsTableViewEnabled(true)}  className={classNames('investment__view-button', { 'active': isTableViewEnabled })}>TABELA</button>
                  {
                    !isTableViewEnabled && <>
                      <button {...clickable({})} onClick={() => setCurrentSlide(prevState => getSlideIndex(prevState, -1, filteredFlats.length - 1))}>
                        <img src={arrowIconSrc} alt="Arrow" />
                      </button>
                      <button {...clickable({ isLeft: false })} onClick={() => setCurrentSlide(prevState => getSlideIndex(prevState, 1, filteredFlats.length - 1))}>
                        <img src={arrowIconSrc} alt="Arrow" />
                      </button>
                    </>
                  }
                </div>
              </div>
              {
                isTableViewEnabled && <div className="table-container">
                  <table className="investment__flats-table">
                  <tr>
                    <th>NR</th>
                    {investment.type !== "HOUSE" && <th>PIETRO</th>}
                    <th>METRAŻ [m<sup>2</sup>]</th>
                    <th>POKOJE</th>
                    <th>STATUS</th>
                    {investment.type !== "HOUSE" && <th>CENA ZA M<sup>2</sup> Z MIEJSCEM POSTOJOWYM [PLN]</th>}
                  </tr>
                  {
                    filteredFlats.map(flat => <tr onClick={() => {
                        setShouldHide({ value: true, flat })
                    }}>
                      <td>{flat.flatNumber}</td>
                      {investment.type !== "HOUSE" && <td>{windowWidth > 1200 ? flat.floor === 0 ? 'parter' : flat.floor : flat.floor}</td>}
                      <td>{flat.area}</td>
                      <td>{flat.rooms}</td>
                      <td><span style={{
                        color: flat.isSold ? 'red' : flat.isReserved ? 'yellow' : 'green'
                      }} >{flat.isSold ? 'sprzedane' : flat.isReserved ? 'rezerwacja' : 'wolne'}</span></td>
                      {!!flat.priceWithParkingSpace && <td>{flat.priceWithParkingSpace}</td>}
                    </tr>)
                  }
                  </table></div>
              }
              {
                !isTableViewEnabled && <div className="investment__flats-carousel-container">
                  <Carousel
                    centerMode
                    centerSlidePercentage={windowWidth <= 760 ? 100 : windowWidth <= 1200 ? 50 : 25}
                    swipeable
                    showStatus={false}
                    renderIndicator={() => {}}
                    renderThumbs={() => {}}
                    renderArrowNext={() => {}}
                    renderArrowPrev={() => {}}
                    selectedItem={currentSlide}
                    emulateTouch
                  >
                    {
                      filteredFlats.map(flat =>
                        <div
                          className="investment__flat-card" style={{ overflow: "hidden" }}
                          onClick={() => {
                            if (windowWidth > 1200) {
                              setShouldHide({ value: true, flat })
                            }
                          }}
                          {...clickable({ customText: 'POWIĘKSZ' })}
                        >
                          <img src={flat.flatImgUrl} alt="Rzut mieszkania" className="investment__flat-card-image" />
                          <div className="investment__flat-card-stats">
                            <div>
                              <div className="investment__flat-card-stat">
                                <img src={areaIconSrc} alt="Area" />
                                <span>{flat.area} m2</span>
                              </div>
                              <div className="investment__flat-card-stat">
                                <img src={roomIconSrc} alt="Room" />
                                <span>{flat.rooms}</span>
                              </div>
                              {investment.type !== "HOUSE" &&
                                <div className="investment__flat-card-stat">
                                  <img src={stairsIconSrc} alt="Floor" />
                                  <span>{flat.floor === 0 ? 'parter' : flat.floor}</span>
                                </div>
                              }
                            </div>
                            <div>
                              <div className="investment__flat-card-stat">
                                <span>{`M${flat.flatNumber}`}</span>
                              </div>
                              <div className="investment__flat-card-stat">
                        <span style={{
                          color: flat.isSold ? 'red' : flat.isReserved ? 'yellow' : 'green'
                        }} >{flat.isSold ? 'sprzedane' : flat.isReserved ? 'rezerwacja' : 'wolne'}</span>
                              </div>
                              {
                                windowWidth <= 1200 &&
                                <div className="investment__flat-card-stat">
                                  <button onClick={() => setShouldHide({ value: true, flat })}>powiększ</button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      )}
                  </Carousel>
                </div>
              }
              <p className="investment__disclaimer">
                Metraże mieszkań podane na rzutach mogą nieznacznie odbiegać od rzeczywistego stanu. Z kolei wartości podane w tabelach odzwierciedlają rzeczywistą powierzchnię.
              </p>
            </div>
          }
          {
            selectedFlat !== null && <Flat flat={selectedFlat} setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} setSelectedFlat={setSelectedFlat} setShouldAppear={setShouldAppear} />
          }
        </section>
    </>

  )
};

export default forwardRef(FlatsSection);