import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { client, GET_INVESTMENTS } from './getInvestments';
import areaIcon from '../assets/icons/area-blue.png';
import locationIcon from '../assets/icons/location.png';
import flatsTotalIcon from '../assets/icons/apartment-blue.png';
import './investmentsCardSlider.scss';

export const getMinMaxProperty = (investment, property = '', shouldRound = true) => {

  if (property === 'floor') {
    return {
      min: Math.min(...investment.flats.map(({ floor }) => floor)),
      max: Math.max(...investment.flats.map(({ floor }) => floor))
    }
  }

  const min = Math.min(...investment.flats.map(floor => Math.min(...floor.flats.map(flat => flat[property]))));
  const max = Math.max(...investment.flats.map(floor => Math.max(...floor.flats.map(flat => flat[property]))));

  return {
    min: shouldRound ? Math.round(min) : min,
    max: shouldRound ? Math.round(max) : max
  }
};

const getLastDigit = number => Number(String(number).split("").reverse()[0]);
const between2And4 = number => number >= 2 && number <= 4;

const InvestmentsCardSlider = ({ setCursorText, setShouldMouseBeScaled }) => {

  const makeClickable = useCallback((text) => ({
    onMouseEnter: () => {
      setCursorText(text);
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  const { loading: isLoading, error, data } = useQuery(GET_INVESTMENTS, { client });

  return (
    <>
      <div className="investment-cards-container">
        {
          isLoading && <div style={{ height: '460px' }}>LADOWANIE</div>
        }
        {
          !isLoading && !error && data.allInwestycjes.map(({ data: investment }, index) =>
            <div className="investment-card" key={`investment-card-${index}`}>
              {
                investment.photos[0] && <img src={investment.photos[0]} alt={investment.displayName} className="investment-card__image" />
              }

              <h4 className="investment-card__title">{investment.displayName}</h4>
              {
                investment.briefDescription && <p className="investment-card__detail">
                  <span className="investment-card__detail-text">{investment.briefDescription}</span>
                </p>
              }
              <p className="investment-card__detail">
                <span className="investment-card__detail-icon"><img src={areaIcon} alt="Area Icon" /></span>
                {
                  (() => {
                    const { min, max } = getMinMaxProperty(investment, 'area');

                    if (min === max) {
                      return <span className="investment-card__detail-text">{min} m2</span>;
                    }

                    return <span className="investment-card__detail-text">{min} - {max} m2</span> ;
                  })()
                }
              </p>
              <p className="investment-card__detail"
                 {...makeClickable("MAPA")}
                 onClick={
                   () =>
                     window.open(
                       `https://google.com/maps/place/${investment.address.street}+${investment.address.buildingNumber},+${investment.address.postalCode}+${investment.address.city}`,
                       '_blank')
                       .focus()}
              >
                <span className="investment-card__detail-icon"><img src={locationIcon} alt="Location Icon" /></span>
                <span className="investment-card__detail-text">{investment.address.street} {investment.address.buildingNumber}, {investment.address.city}</span>
              </p>
              <p className="investment-card__detail">
                <span className="investment-card__detail-icon"><img src={flatsTotalIcon} alt="Total Flats Icon" /></span>
                <span className="investment-card__detail-text">
                  {investment.totalFlats} {investment.type === "HOUSE" ?
                  `dom${between2And4(getLastDigit(investment.totalFlats)) ? "y" : "ów"}` : `mieszka${between2And4(getLastDigit(investment.totalFlats)) ? 'nia' : 'ń'}`}
                  </span>
              </p>
              <Link disabled={investment.isDisabled} to={investment.isDisabled ? "#" : `/inwestycje/${investment.id}`} className={`investment-card__button ${investment.isDisabled ? "investment-card__button--disabled" : ""}`} {...(investment.isDisabled ? {} : makeClickable("ODKRYJ"))}>{investment.isDisabled ? "Więcej wkrótce!" : "zobacz szczegóły"}</Link>
            </div>
          )
        }
      </div>
      <p className="investments-section__total">Liczba inwestycji: {!isLoading && !error && data.allInwestycjes.length}</p>
    </>
  )
};

export default InvestmentsCardSlider;