import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { gsap, Expo } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Homepage from './pages/Homepage';
import AllInvestments from './pages/investments/Investments';
import Investment from './pages/investment/Investment';
import Cursor from './Cursor';
import { useWindowDimensions } from '../shared/useWindowsDimensions';
import ContactPage from './pages/contact/Contact';

/**
 * @return {null}
 */

function ScrollToTop({ setShouldMouseBeScaled, setCursorText }) {
  const { pathname } = useLocation();
  const transitionDivRef = useRef(null);
  const transitionDivBgRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    gsap.set("body", { overflow: "hidden" });
    gsap.set(transitionDivRef.current, { display: "block", width: "100vw" });
    const tl = gsap.timeline();

    tl.set(transitionDivBgRef.current, {
      opacity: 1,
      display: "block"
    });

    tl.fromTo(transitionDivRef.current, {
      height: 0,
    }, {
      height: "100vh",
      transformOrigin: "bottom",
      duration: .7,
      ease: Expo.easeInOut
    });

    tl.to(transitionDivRef.current, {
      width: 0
    });

    tl.to(transitionDivBgRef.current, {
      opacity: 0,
      display: "none",
      duration: .7,
      onComplete: () => gsap.set("body", { overflow: "" })
    });

    setShouldMouseBeScaled(false);
    setCursorText('');
  }, [setCursorText, setShouldMouseBeScaled, pathname]);

  return (
    <div className="transition-div-background" ref={transitionDivBgRef}>
      <div className="transition-div" ref={transitionDivRef} />
    </div>
  );
}


gsap.registerPlugin(ScrollTrigger);

function App() {
  const [shouldMouseBeScaled, setShouldMouseBeScaled] = useState(false);
  const [cursorText, setCursorText] = useState('');

  const { width } = useWindowDimensions();

  return (
    <div className="App">
      <Router>
        <ScrollToTop setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />
        <Routes>
          <Route exact path="/" element={<Homepage setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />} />
          <Route exact path="/inwestycje" element={<AllInvestments setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />} />
          <Route exact path="/inwestycje/:id" element={<Investment setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />} />
          <Route exact path="/kontakt" element={<ContactPage setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} />} />
        </Routes>
      </Router>

      {width >= 1200 && <Cursor shouldMouseBeScaled={shouldMouseBeScaled} cursorText={cursorText} />}
    </div>
  );
}

export default App;
