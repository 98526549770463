import React, { useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Footer from '../components/sections/footer/Footer';
import closeIconSrc from '../assets/icons/close-gold.png';
import './menuView.scss';
import { useLocation } from 'react-router-dom';

const Menu = ({ setCursorText, setShouldMouseBeScaled, setIsMenuVisible }) => {

  const { pathname } = useLocation();

  const menuMainRef = useRef(null);
  const coverRef = useRef(null);
  const coverDivsRefs = [];

  const [shouldHide, setShouldHide] = useState(false);

  const makeClickable = useCallback((text) => ({
    onMouseEnter: () => {
      setCursorText(text);
      setShouldMouseBeScaled(true);
    },
    onMouseLeave: () => {
      setCursorText('');
      setShouldMouseBeScaled(false);
    }
  }), [setCursorText, setShouldMouseBeScaled]);

  useEffect(() => {
    gsap.to("body", {
      overflow: "hidden"
    });
    const tl = gsap.timeline();

    tl.to(coverDivsRefs, {
      width: 0,
      transformOrigin: "right",
      stagger: 0.15,
      duration: .8,
      onComplete: () => {
        gsap.set(coverRef.current, {
          display: "none"
        })
      }
    });
  }, []);

  useEffect(() => {
    if (shouldHide) {
      gsap.set(coverRef.current, {
        display: "block"
      });

      const tl = gsap.timeline();

      tl.fromTo(coverDivsRefs, {
        width: "0%",
        transformOrigin: "right",
      }, {
        width: "100%",
        stagger: 0.15,
        duration: .8,
      });

      tl.to(menuMainRef.current, {
        opacity: 0,
        onComplete: () => {
          gsap.to("body", {
            overflow: ""
          });

          setIsMenuVisible(false)
        }
      })
    }
  }, [shouldHide]);

  return (
    <main className="menu-view" ref={menuMainRef}>
      <button onClick={() => setShouldHide(true)} {...makeClickable('ZAMKNIJ')}>
        <img src={closeIconSrc} alt="Close" />
      </button>
      <Footer setShouldMouseBeScaled={setShouldMouseBeScaled} setCursorText={setCursorText} shouldRenderHomepageLink activeLink={pathname} />
        <div id="menu-view-cover" ref={coverRef}>
          <div id="menu-view-cover--1" ref={ref => coverDivsRefs[0] = ref} />
          <div id="menu-view-cover--2" ref={ref => coverDivsRefs[1] = ref} />
          <div id="menu-view-cover--3" ref={ref => coverDivsRefs[2] = ref} />
        </div>
    </main>
  )
};

export default Menu;